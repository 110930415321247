<template>
	<div class="container Auth mx-auto">
		<v-card class="d-flex flex-column" elevation="0">
			<v-card-title class="px-0 pt-0">
				<h1 class="heading">외국인 본인인증</h1>
			</v-card-title>
			<v-card-subtitle class="pl-0 text-base" style="max-width: 600px">
				해외에 거주하여 본인명의 휴대폰이나 아이핀으로 직접 성인인증을 하실 수
				없는 경우, 고객센터를 통해 예외적으로 본인 확인 후 수동으로 성인인증을
				도와드릴 수 있습니다.
			</v-card-subtitle>

			<v-card class="pa-4">
				<v-card-text class="text-base">
					<div class="fill-height">
						<p>
							본인 확인을 위해 아래의 정보를 접수하셔서 성인인증을 요청해
							주세요.
						</p>
						<p class="font-weight-bold">
							* 필요 서류 : 거주하는 국가(해외)에서 발급한 신분증 사본
						</p>
						<p>
							<strong>처리 가능한 신분증</strong>
							<br />
							거주하는 국가에서 발급한 신분증 (해외 여권, 해외 면허증, 해외 거주
							허가증 등)
						</p>
					</div>

					<div class="mt-2">
						<ul>
							<li>
								영문 이름, 생년월일, 성별을 확인할 수 있어야 합니다.
								<br />
								<span class="ml-1">
									(Identification must include NAME, DATE of Birth, GENDER.)
								</span>
							</li>
							<li>사진 및 주소는 가린 후 접수해 주셔야 합니다.</li>
							<li>유효기간이 지난 신분증은 처리 불가합니다.</li>
							<li>
								정보 확인 및 해외 요청 시 시차로 인해 처리 시간이 다소
								소요됩니다.
								<br />
								<span class="ml-1">
									(Processing may be delayed due to identification and time
									difference.)
								</span>
							</li>
						</ul>
					</div>

					<div class="mt-5">
						<p>
							<strong>처리 불가능한 신분증</strong>
							<br />
							대한민국 정부에서 발급한 신분증 (여권, 주민등록증, 운전면허증 등)
						</p>
					</div>
				</v-card-text>
				<v-card-actions>
					<v-btn
						color="primary"
						:to="{ name: 'mypage-dashboard' }"
						style="width: 100%"
					>
						확인
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-card>
	</div>
</template>

<script>
import store from '@/store'
import { onMounted, computed } from '@vue/composition-api'
import { useRouter } from '@core/utils'

export default {
	setup() {
		const { router } = useRouter()

		const isNationalitySet = () => {
			const profile = computed(() => store.getters['user/getMeDetail'])
			if (profile.value.nationality) {
				router.push({ name: 'mypage-dashboard' })
			}
		}

		store.dispatch('app/setIsLogoWhite', false)

		onMounted(() => {
			isNationalitySet()
		})
	},
}
</script>

<style lang="scss" scoped>
.full-width {
	width: 100%;
	max-width: 100%;
}

.Auth {
	height: 100vh;
	margin-top: 10em;

	@media screen and (max-width: 768px) {
		margin-top: 5em;
	}

	.v-card {
		width: 100%;

		@media screen and (max-width: 768px) {
			padding: 22px;
		}
	}

	h1.heading {
		text-align: left;
		font-size: 25px;
		font-weight: normal;
		color: #303441;
		line-height: normal;
		margin-top: 0;
	}

	.span-text {
		color: #888;
		font-size: 14px !important;
		font-weight: 500 !important;
		cursor: pointer;
	}

	button {
		width: 100%;
		min-width: 100% !important;
		min-height: 40px;
		font-size: 14px !important;
	}
}
</style>
